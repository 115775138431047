import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsXLg } from 'react-icons/bs';
import { useDebounce } from '../../hooks';

interface IChannelFilterProps extends React.AllHTMLAttributes<HTMLDivElement> {
	onTermChange: (term: string) => void;
}
const ChannelFilter = ({ onTermChange }: IChannelFilterProps) => {
	const [term, setTerm] = useState('');
	const debouncedTerm = useDebounce(term, 500);

	useEffect(() => {
		onTermChange(debouncedTerm.trim());
	}, [debouncedTerm, onTermChange]);

	const onChangeTerm = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value || '';
		setTerm(newValue);
	}, []);

	const onClearTerm = useCallback(() => {
		setTerm('');
	}, []);

	return (
		<InputGroup className="top-sticky mb-2 z-2 bg-body" size="sm">
			<Form.Control placeholder="Фільтрація списку каналів..." value={term} onChange={onChangeTerm} />
			<Button
				variant={'outline-secondary'}
				onClick={onClearTerm}
				disabled={debouncedTerm === ''}
				title="Скасувати фільтрацію"
			>
				<BsXLg />
			</Button>
		</InputGroup>
	);
};

export default ChannelFilter;
