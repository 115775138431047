import { IEntity, TDictionary } from './common';

export interface IChannel {
	ID: number;
	YOUTUBE_ID: string;
	TITLE: string;
	DESCRIPTION?: string;
	LOGO: string;
	LANGUAGE: string;
	ACTIVE: boolean;
	COUNTRY: string;
	CUSTOM_URL: string | null;
	SUBSCRIBER_COUNT: number;
}

// export type TChannels = { [key: number]: IChannel };
export type TChannels = TDictionary<number, IChannel>;

export interface IViewChannel {
	count: number;
	max_date?: string;
	min_date?: string;
	channel: IChannel;
	youtubeId: string;
}

export const CHANNEL_SKELETON: IChannel = {
	ID: 0,
	YOUTUBE_ID: '',
	TITLE: '',
	DESCRIPTION: '',
	LOGO: '',
	LANGUAGE: 'UN',
	ACTIVE: true,
	COUNTRY: 'UN',
	CUSTOM_URL: null,
	SUBSCRIBER_COUNT: 0,
};

/**
 * На додачу до IEntity даний інтерфейс містить таблицю трансляції
 * youtubeId каналу до його ідентифікатора в БД.
 */
export interface IAllChannels extends IEntity<number, IChannel> {
	youtubeMapping: TDictionary<string, number>;
	// youtubeMapping: TDictionary<string, keyof IAllChannels['entities']>;
}
