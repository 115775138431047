import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import PageTitle from '../../components/PageTitle';
import { IViewChannel } from '../../interfaces/channels';
import { formatNumberToHumanReadable } from '../../utils';
import ChannelInfoDialog from './channelInfoDialog';

interface IChannelListItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	channel: IViewChannel;
	active?: boolean;
}
const ChannelListItem = ({ channel, active = false }: IChannelListItemProps) => {
	const [showInfo, setShowInfo] = useState(false);
	const {
		channel: { TITLE, LOGO, SUBSCRIBER_COUNT },
		count,
		max_date,
		min_date,
		youtubeId,
	} = channel;

	const onShowClick = useCallback((event?: React.MouseEvent<HTMLAnchorElement>) => {
		event?.preventDefault();
		event?.stopPropagation();
		setShowInfo((show) => !show);
	}, []);

	const subscribers_count =
		SUBSCRIBER_COUNT !== 0 ? ` Підписалося ${formatNumberToHumanReadable(SUBSCRIBER_COUNT)} користувачів` : undefined;
	return (
		<ChannelButton
			type="button"
			className={classNames(
				'list-group-item list-group-item-action d-flex gap-2 align-items-center',
				active && 'active top-bottom-sticky'
			)}
			data-channel-id={youtubeId}
			title={subscribers_count}
		>
			{active && <PageTitle title={TITLE} />}
			{LOGO ? (
				<img src={LOGO} alt={TITLE} className="flex-shrink-0 channel-image white-halo" />
			) : (
				<Skeleton className="channel-image" />
			)}
			<div className="min-width-0">
				{TITLE ? (
					<div className="d-flex gap-1">
						<span className="text-truncate" title={`${TITLE}${subscribers_count ? ` (${subscribers_count})` : ''}`}>
							{TITLE}
						</span>
						<a // eslint-disable-line
							href="#"
							className={classNames('flex-shrink-0', active ? 'link-light' : 'link-secondary')}
							onClick={onShowClick}
						>
							<BsInfoCircle className="mt-n1" />
						</a>
					</div>
				) : (
					<Skeleton />
				)}
				{(min_date || max_date) && (
					<div
						className="text-truncate opacity-75"
						title={max_date ? 'Дата останнього повідомлення' : 'Дата першого повідомлення'}
					>
						{dayjs(max_date || min_date).format('L LT')}
						{/* {dayjs(lastDate).fromNow()} */}
					</div>
				)}
			</div>
			<span className="badge rounded-pill ms-auto" title="Кількість знайдених сюжетів">
				{count}
			</span>
			{showInfo && <ChannelInfoDialog channel={channel.channel} onCloseDialog={onShowClick} />}
		</ChannelButton>
	);
};

export default ChannelListItem;

const ChannelButton = styled.button`
	--bs-list-group-active-bg: var(--selected-bg-color);
	--bs-list-group-active-border-color: var(--selected-bg-color);
	.badge {
		background-color: var(--list-badge-bg-color, var(--bs-secondary));
	}
	&.active {
		--sticky-top-position: 0;
		.badge {
			--list-badge-bg-color: #5c3210;
			--list-badge-bg-color: color-mix(in oklch, var(--selected-bg-color) 30%, black 30%);
		}
	}
`;
