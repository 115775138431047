import React, { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';
import styled from 'styled-components';
import { useQueryParam } from '../../hooks';
import { CHANNEL_SKELETON, IAllChannels, IViewChannel } from '../../interfaces/channels';
import { TFacetSearchVideoResults } from '../../services/tvApi';
import ChannelFilter from './ChannelFilter';
import ChannelListItem from './channelListItem';

interface IChannelListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	channels?: IAllChannels;
	searchFacets?: TFacetSearchVideoResults;
}
const ChannelList = ({ channels, searchFacets }: IChannelListProps) => {
	const [items, setItems] = useState<IViewChannel[]>([]);
	const [filtered, setFiltered] = useState(false);
	const [filteredItems, setFilteredItems] = useState(items);
	const { queryParam: activeChannelId, setQueryParam: setActiveChannelId } = useQueryParam('channel');

	useEffect(() => {
		if (!searchFacets || !channels) return;
		const { entities, youtubeMapping } = channels;
		const newItems = searchFacets
			.map((f) => {
				const channel = entities[youtubeMapping[f.youtubeId] || -1];
				if (!channel) return undefined;
				return {
					count: f.count,
					max_date: f.max_date,
					min_date: f.min_date,
					channel: channel || { ...CHANNEL_SKELETON },
					youtubeId: f.youtubeId,
				} as IViewChannel;
			})
			.filter(Boolean) as IViewChannel[];
		/**
		 * Можливий варіант, коли користувач обрав канал, потім змінив умови пошуку і з'ясувалось, що
		 * в обраному канали сюжети відсутні. Для того, щоб користувач розумів, що його результати
		 * обмежені обраним каналом, виводимо його пусту плашку також.
		 */
		if (activeChannelId) {
			const activeChannelInList = newItems.find(({ youtubeId }) => youtubeId === activeChannelId);
			if (!activeChannelInList)
				newItems.unshift({
					count: 0,
					channel: channels.entities[channels.youtubeMapping[activeChannelId] || -1] || { ...CHANNEL_SKELETON },
					youtubeId: activeChannelId,
				});
		}
		setItems(newItems as IViewChannel[]);
	}, [channels, searchFacets, activeChannelId]);

	const onFilterTermChange = useCallback(
		(term: string) => {
			const re = new RegExp(term.replace(/[йуеіїаоєяиюыэё]/gi, '.'), 'i');
			const filteredChannels =
				term === ''
					? items
					: items.filter(
							({ channel }) =>
								re.test(`${channel.TITLE}_${channel.CUSTOM_URL}`) || channel.YOUTUBE_ID === activeChannelId
					  );
			setFilteredItems(filteredChannels);
			setFiltered(term !== '');
		},
		[items, activeChannelId]
	);

	const onChannelClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const { channelId } = ((event.target as HTMLElement).closest('button') as HTMLButtonElement).dataset;
			if (!channelId) return;
			const clickedChannelId = channelId;
			setActiveChannelId(clickedChannelId !== activeChannelId ? clickedChannelId : undefined);
		},
		[activeChannelId, setActiveChannelId]
	);

	if (!searchFacets) return null;

	return (
		<>
			<ChannelFilter onTermChange={onFilterTermChange} />
			<ChannelsContainer className="list-group top-sticky overflow-auto" onClick={onChannelClick}>
				{filtered && (
					<Alert variant="warning" className="text-small mb-2 py-1 px-2">
						<BsInfoCircle className="mt-n1" /> Фільтрація застосовується лише до списку каналів і не впливає на показані
						сюжети.
					</Alert>
				)}
				{filteredItems.map((channel) => (
					<ChannelListItem key={channel.youtubeId} channel={channel} active={channel.youtubeId === activeChannelId} />
				))}
			</ChannelsContainer>
		</>
	);
};

export default ChannelList;

const ChannelsContainer = styled.div`
	--sticky-top-position: 103px;
	max-height: calc(100vh - 103px);
`;
