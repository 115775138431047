import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import React, { Suspense, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import LiveTimeController from '../../components/LiveTimeController';
import Preloader from '../../components/Preloader';
import SplashScreen from '../../components/SplashScreen';
import WorkPlace from '../../components/Workplace';
import ErrorHandler from '../../components/errorHandler';
import Layout from '../../components/layout';
import QueryNavigate from '../../components/queryNavigate';
import { DEFAULTS, IAuth0AccessToken, getTokenSilentlyAuthOptions } from '../../init';
import { RootState } from '../../store';
import SelectedVideo from '../video/SelectedVideo';
import AccessTokenUpdater from './AccessTokenUpdater';
import { changePageIsVisible, changePermissions } from './appSlice';

const LazyPageNotFound = React.lazy(() => import('../../components/PageNotFound'));

const router = createHashRouter([
	{
		path: '/',
		element: <Layout />,
		errorElement: <ErrorHandler />,
		children: [
			{
				path: 'video',
				element: <WorkPlace />,
				children: [
					{
						path: ':videoId',
						element: <SelectedVideo />,
						children: [
							{
								path: ':time',
								element: null,
							},
						],
					},
				],
			},
			{
				index: true,
				element: <QueryNavigate to="video" />,
			},
			{
				path: '*',
				element: (
					<Suspense fallback={<Preloader />}>
						<LazyPageNotFound />
					</Suspense>
				),
			},
		],
	},
]);
interface IAppProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const App = ({ changePageIsVisible, changePermissions }: IAppProps) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

	useEffect(() => {
		const handleVisibilityChange = () => {
			changePageIsVisible(document.visibilityState === 'visible');
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
	}, [changePageIsVisible]);

	useEffect(() => {
		const updatePermissions = async (isAuthenticated: boolean) => {
			const accessToken = isAuthenticated
				? await getAccessTokenSilently(getTokenSilentlyAuthOptions(DEFAULTS.availableScopes))
				: undefined;
			changePermissions(accessToken ? (jwtDecode(accessToken) as IAuth0AccessToken).scope.split(' ') : undefined);
		};

		updatePermissions(isAuthenticated);
	}, [isAuthenticated, getAccessTokenSilently, changePermissions]);

	// useEffect(() => {
	// 	// const timeoutMs = 10000;  // for debug
	// 	const timeoutMs = dayjs().add(1, 'day').startOf('day').add(1, 'second').diff(dayjs());
	// 	const handle = setTimeout(changeCurrentDate, timeoutMs);
	// 	// console.log(timeoutMs / 1000 / 3600);
	// 	return () => {
	// 		clearTimeout(handle);
	// 	};
	// }, [currentDate, changeCurrentDate]);

	if (!(isAuthenticated || isLoading)) return <SplashScreen />;

	return (
		<>
			<RouterProvider router={router} />
			<AccessTokenUpdater />
			<LiveTimeController />
		</>
	);
};

const mapState = (state: RootState) => ({
	// currentDate: state.app.currentDate,
	// searchResponse: state.search.searchResponse,
});

const mapDispatch = { changePageIsVisible, changePermissions };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
