import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import TextIcon from '../../components/textIcon';
import { useAppDispatch } from '../../store';
import SavedQueries from './savedQueries';
import { changeSearchTs } from './searchSlice';

const SearchForm = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [newTerm, setNewTerm] = useState(searchParams.get('term') || '');
	const refSearch = useRef<HTMLInputElement>(null);
	const dispatch = useAppDispatch();

	const onTermChange = (event: React.ChangeEvent<HTMLInputElement>) => setNewTerm(event.target.value);

	useEffect(() => {
		const termFromParams = searchParams.get('term') || '';
		if (termFromParams !== newTerm) setNewTerm(termFromParams);
	}, [searchParams]); // eslint-disable-line

	const searchTerm = (term: string, bySavedQuery: boolean) => {
		if (term !== searchParams.get('term') || '') {
			searchParams.set('term', term);
			if (bySavedQuery) searchParams.set('severity', '0');
			setSearchParams(searchParams);
		} else dispatch(changeSearchTs());
		refSearch.current?.focus();
		refSearch.current?.select();
	};

	const onSearchFormSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation();
		searchTerm(newTerm, false);
	};

	useEffect(() => {
		refSearch.current?.focus();
		setTimeout(() => refSearch.current?.select(), 0);
	}, []);

	const onSavedQueryClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const queryControl = (event.target as HTMLElement).closest('.sq-query') as HTMLAnchorElement;
		if (!queryControl) return;
		const { term } = queryControl.dataset;
		if (!term) return;
		searchTerm(term, true);
	};

	return (
		<Form className="flex-grow-1" role="search" onSubmit={onSearchFormSubmit}>
			<InputGroup onClick={onSavedQueryClick}>
				<Form.Control
					name="term"
					type="text"
					className="form-control"
					placeholder="введіть пошуковий рядок..."
					maxLength={3900}
					autoComplete="on"
					value={newTerm}
					onChange={onTermChange}
					ref={refSearch}
				/>
				<Button type="submit" variant="warning">
					<TextIcon Icon={BsSearch}>
						<span className="d-none d-xl-inline">Знайти</span>
					</TextIcon>
				</Button>
				<SavedQueries />
			</InputGroup>
		</Form>
	);
};

export default SearchForm;
