import dayjs from 'dayjs';
import React from 'react';

interface IHumanReadableTimeProps extends React.AllHTMLAttributes<HTMLDivElement> {
	isoDate: string;
}
const HumanReadableTime = ({ isoDate }: IHumanReadableTimeProps) => {
	const published = dayjs(isoDate);
	return (
		<div title={published.format('L LT')} className="live-time" data-time={isoDate}>
			<span className="fw-500">{published.fromNow()}</span>
			<span className="text-muted ms-1">
				({published.format(published.isSame(dayjs(), 'date') ? '[Сьогодні о ]LT' : 'L LT')})
			</span>
		</div>
	);
};

export default HumanReadableTime;
