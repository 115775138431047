import { DEFAULTS, ITextPartInterval } from './init';
import { TNumberWithNull } from './interfaces/common';

/**
 * Робота з інтервалами text snippets
 */

/**
 * Перевіряє, чи належить значення інтервалу [start, end)
 * @param value значення, що зрівнюється
 * @param start початкове значення
 * @param end кінцеве значення
 * @returns boolean, що що показує, чи входить значення в заданий період
 */
export const valueInPeriod = (value: number, start: number, end: number) => start <= value && value < end;

/**
 * Функція порівняння для використання в binarySearch
 * @param param0 text snippet
 * @param current current playedTime
 * @returns результат порівняння поточного часу з періодом:
 *   - якщо поточний час входить в період, то повертається 0;
 *   - якщо період передує поточному часу, то повертається -1;
 *   - якщо період слідує за поточним часом, то повертається 1.
 */
export const periodCompareFunction = <T extends ITextPartInterval>({ start, end }: T, current: number) => {
	if (valueInPeriod(current, start, end)) return 0;
	return current < start ? 1 : -1;
};
// export const binarySearch = <T>(sortedArray: T[], key: T) => {
// 	let start = 0;
// 	let end = sortedArray.length - 1;

// 	while (start <= end) {
// 		let middle = Math.floor((start + end) / 2);

// 		if (sortedArray[middle] === key) {
// 			// found the key
// 			return middle;
// 		} else if (sortedArray[middle] < key) {
// 			// continue searching to the right
// 			start = middle + 1;
// 		} else {
// 			// search searching to the left
// 			end = middle - 1;
// 		}
// 	}
// 	// key wasn't found
// 	return -1;
// };

export interface IBinarySearchResult {
	/**
	 * Індекс знайденого елемента, або null, якщо елемент не знайдено
	 */
	index: TNumberWithNull;
	/**
	 * Індекс попереднього елемента, або null, якщо попередній елемент відсутній
	 */
	previous: TNumberWithNull;
	/**
	 * Індекс наступного елемента, або null, якщо наступний елемент відсутній
	 */
	next: TNumberWithNull;
}
/**
 * Повертає інформацію про індекс знайденого в масиві цільового елемента та його оточення.
 * Пошук здійснюється за допомогою бінарного пошуку.
 * @param sortedArray попередньо відсортований масив
 * @param key цільове значення
 * @param compareFunction функція порівняння, що приймає елемент масиву та цільове значення і повертає:
 *   0, якщо елемент дорівнює цільовому значенню;
 *   1, якщо елемент більше цільового значення;
 *   -1, якщо елемент менше цільового значення
 * @returns змінна типу IBinarySearchResult
 */
export const binarySearch = <T, Q>(
	sortedArray: T[],
	key: Q,
	compareFunction: (value: T, key: Q) => number
): IBinarySearchResult => {
	let start = 0;
	const lastIndex = sortedArray.length - 1;
	let end = lastIndex;

	while (start <= end) {
		let middle = Math.floor((start + end) / 2);
		const compareResult = compareFunction(sortedArray[middle], key);

		if (compareResult === 0) {
			// found the key
			return {
				index: middle,
				previous: middle !== 0 ? middle - 1 : null,
				next: middle < lastIndex ? middle + 1 : null,
			} as IBinarySearchResult;
		} else if (compareResult < 0) {
			// continue searching to the right
			start = middle + 1;
		} else {
			// search searching to the left
			end = middle - 1;
		}
	}
	// key wasn't found
	return {
		index: null,
		previous: sortedArray[0] === undefined || 0 < compareFunction(sortedArray[0], key) ? null : end,
		next: sortedArray[lastIndex] === undefined || compareFunction(sortedArray[lastIndex], key) < 0 ? null : start,
	} as IBinarySearchResult;
};

/**
 * Інші функції
 */

export const getDifferentNumber = (desired: number, current = '') =>
	`${desired.toString() !== current ? desired.toString() : Math.round(desired + 0.5).toString()}`;
// export const getDifferentNumber = (desired: number, current = '', adding = 1) =>
// `${desired.toString() !== current ? desired.toString() : (desired + adding).toString()}`;

export const formatNumberToHumanReadable = (num: number, locale = DEFAULTS.locale) => {
	if (num >= 1_000_000) {
		return new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(num / 1_000_000) + ' млн.';
	} else if (num >= 1_000) {
		return new Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(num / 1_000) + ' тис.';
	} else {
		return new Intl.NumberFormat(locale, { maximumFractionDigits: 0 }).format(num);
	}
};
