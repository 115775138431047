import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import styled from 'styled-components';
import CountryFlag from '../../components/countryFlag';
import DotSpinner from '../../components/DotSpinner';
import { IChannel } from '../../interfaces/channels';
import { useChannelInfoQuery } from '../../services/tvApi';
import { formatNumberToHumanReadable } from '../../utils';

interface IChannelInfoDialogProps extends React.AllHTMLAttributes<HTMLDivElement> {
	channel: IChannel;
	onCloseDialog: () => void;
}
const ChannelInfoDialog = ({ channel, onCloseDialog }: IChannelInfoDialogProps) => {
	const [show, setShow] = useState(true);
	const { data, isFetching } = useChannelInfoQuery(channel.ID);
	const [info, setInfo] = useState(channel);

	useEffect(() => {
		if (data) setInfo(data);
	}, [data]);

	const handleClose = useCallback(() => {
		setShow(false);
		setTimeout(onCloseDialog, 500);
	}, [onCloseDialog]);

	const { YOUTUBE_ID, TITLE, LOGO, COUNTRY, CUSTOM_URL, SUBSCRIBER_COUNT, DESCRIPTION } = info;
	const channelLink = CUSTOM_URL
		? `https://www.youtube.com/${CUSTOM_URL}`
		: `https://www.youtube.com/channel/${YOUTUBE_ID}`;

	return (
		<Modal show={show} onHide={handleClose} centered size="lg">
			<Modal.Header closeButton>
				<Modal.Title>Про YouTube-канал</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pb-4">
				<div className="d-flex gap-3">
					<ChannelImage src={LOGO} alt={TITLE} className="flex-shrink-0 channel-image white-halo flex-shrink-0" />
					<div className="flex-grow-1">
						<div className="d-flex justify-content-between align-items-center gap-2">
							<h1 className="mb-0">
								{TITLE}
								<a
									href={channelLink}
									target="_blank"
									rel="noreferrer"
									title="Сторінка каналу на YouTube"
									className="ms-2"
								>
									<BsBoxArrowUpRight className="mt-n1 text-small" />
								</a>
							</h1>
							<CountryFlag country={COUNTRY} title={`Країна каналу — ${COUNTRY}`} className="fs-4 flex-shrink-0" />
						</div>
						{SUBSCRIBER_COUNT !== 0 && (
							<div className="mt-3 fw-bold">
								{CUSTOM_URL} • Підписалося {formatNumberToHumanReadable(SUBSCRIBER_COUNT)} користувачів
							</div>
						)}
						{DESCRIPTION && (
							<Description className="mt-3 overflow-auto">
								{isFetching && <DotSpinner>Завантаження опису</DotSpinner>}
								{DESCRIPTION.split('\n')
									.filter(Boolean)
									.map((text, index) => (
										<p key={index}>{text}</p>
									))}
							</Description>
						)}
					</div>
				</div>
			</Modal.Body>
			{/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
		</Modal>
	);
};

export default ChannelInfoDialog;

const ChannelImage = styled.img`
	--channel-img-size: 5.3rem;
`;

const Description = styled.div`
	max-height: 62vh;
	p {
		margin-bottom: 0.5rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
`;
